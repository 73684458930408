.geography {
  background-image: url('../img/bg-geography.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 120vh;
  position: relative;

  &__tree {
    position: absolute;
    left: 0;
    top: -7%;
  }

  &__bushes {
    position: absolute;
    bottom: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1225px;
    row-gap: 21px;
    column-gap: 35px;
  }

  &__item {
    overflow: hidden;
    border-radius: 16px;
  }

  &__card {
    min-width: 513px;
    background: #FFFFFF;
    box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.13);
    border-radius: 16px;
    position: relative;
  }

  &__content {
    padding: 100px 0 59px 55px;
  }

  &__name {
    max-width: 384px;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    letter-spacing: -0.015em;
    color: var(--black-color);
    margin-bottom: 45px;
  }

  &__block {
    position: absolute;
    top: -46.5px;
    left: 55px;
    background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
    box-shadow: 0px 27px 38px rgba(38, 84, 164, 0.4);
    border-radius: 13px;
    width: 93px;
    height: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  &__img {
    border-radius: 16px;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}
