.header {
  padding: 27px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 15px;

    &_text {
      font-weight: 500;
      font-size: 14px;
      line-height: 92.9%;
      color: #999999;
      max-width: 135px;
    }
  }
}
