.footer {
  background: #434343;
  padding: 50px 0;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 55px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__location {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  &__gps {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 178.1%;
    color: #B9B6B6;
  }

  &__descr {
    font-weight: 600;
    font-size: 15px;
    color: var(--white-color);
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    display: flex;
    align-items: center;
    gap: 10px;

    &_link {
      color: var(--white-color);
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    &_job {
      font-family: 'Gilroy';
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: var(--white-color);
      width: 90px;
      text-align: right;
      margin-left: auto;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: linear-gradient(92.06deg, #7EC05F 4.57%, #89CD68 98.91%);
        width: 3px;
        height: 3px;
      }
    }
  }

  &__link {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--white-color);
    transition: .3s color ease-in-out;

    &:hover {
      color: silver;
    }
  }

  &__copyright {
    color: var(--white-color);
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }
}
