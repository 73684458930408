.consultation {
  padding-bottom: 80px;

  &__content {
    display: flex;
    gap: 58px;
  }

  &__title {
    max-width: 386px;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: var(--black-color);
    margin-bottom: 38px;

    span {
      font-weight: 500;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 35px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 19px;
  }

  &__block {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    vertical-align: middle;
    background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  }

  &__descr {
    max-width: 324px;
    font-weight: 500;
    font-size: 18px;
    line-height: 133.9%;
    color: var(--black-color);
  }

  &__person {
    overflow: hidden;
    border-radius: 16px;
  }

  &__img {
    border-radius: 16px;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 118px;
  }

  &__data {
    padding-left: 80px;
  }

  &__name {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--black-color);
  }

  &__job {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: var(--black-color);

    span {
      font-weight: 700;
    }
  }
}
