@media (max-width: 320px) {
  .header .header__logo img {
    width: 120px !important;
  }
}
@media (max-width: 425px) {
  #hero {
    background-size: auto;
    padding-bottom: 70px !important;
  }
  #hero .hero__title {
    font-size: 21px !important;
  }
  .benefits .benefits__text {
    font-size: 14px !important;
    max-width: 100% !important;
    color: #000000;
    text-align: center;
  }
  .benefits .benefits__item {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  .hero .hero__video .hero__video_block {
    width: 55px !important;
    height: 55px !important;
  }
  .hero .hero__video .hero__video_icon {
    width: 25px;
    height: 25px;
  }
  .header .header__social {
    display: none;
  }
  section .section .section__title {
    font-size: 21px !important;
    line-height: 25px !important;
  }
  .form-consult .form-consult__text {
    font-size: 14px !important;
  }
  .form-consult .form-consult__footer .btn--submit {
    width: 226px !important;
    height: 64px !important;
    font-size: 12px !important;
  }
  .form-consult .form-consult__input {
    padding: 17px 60px !important;
    font-size: 11px !important;
  }
  .form-consult .form-consult__sms {
    font-size: 11px;
  }
  .slider-directory .slider-directory__count {
    font-size: 12px;
  }
  .slider-directory .slider-directory__name {
    font-size: 18px;
  }
  .slider-directory .slider-directory__button .btn--more {
    width: 240px !important;
    font-size: 12px !important;
    padding-bottom: 0;
  }
  .slider-directory .slider-directory__content {
    padding: 20px 0 !important;
  }
  #directory .directory__title {
    font-size: 21px !important;
    line-height: 27px !important;
  }
  #project .project__title {
    font-size: 21px !important;
    line-height: 27px !important;
  }
  .slider-reviews .slider-reviews__descr {
    font-size: 13px;
  }
  .slider-reviews .slider-reviews__name {
    font-size: 15px;
  }
  .slider-reviews .slider-reviews__city {
    font-size: 14px;
  }
  .slider-reviews .slider-reviews__img {
    width: 57px;
    height: 57px;
  }
  .slider-reviews .slider-reviews__content {
    padding: 13px 20px;
  }
  .slider-project .slider-project__content {
    padding: 14px !important;
    gap: 20px;
  }
  .slider-project .slider-project__name {
    font-size: 17px !important;
    line-height: 17px !important;
  }
  .slider-project .slider-project__descr {
    font-size: 14px !important;
  }
  .slider-project .slider-project__descr:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .slider-project .slider-project__time {
    font-size: 15px;
  }
  .slider-project .slider-project__price {
    font-size: 15px;
  }
  .slider-project .slider-project__info {
    margin-top: 10px !important;
    gap: 2px;
    margin-bottom: 15px !important;
  }
  .swiper-pagination-bullet {
    width: 60px !important;
  }
  #reasons .reasons__card--full {
    min-width: 100%;
  }
  .reasons .reasons__card {
    max-width: 100%;
  }
  .reasons .reasons__name {
    font-size: 26px;
    line-height: 127.6%;
    margin-bottom: 15px;
  }
  .reasons .reasons__descr {
    font-size: 15px;
    line-height: 113.1%;
  }
  .reasons .reasons__content {
    padding: 35px;
  }
  .consultation .consultation__item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .consultation .consultation__descr {
    text-align: center;
    max-width: 100%;
  }
  #consultation .consultation__name {
    font-size: 17px;
    line-height: 17px;
  }
  #consultation .consultation__job {
    font-size: 14px;
    line-height: 15px;
  }
  #geography .geography__content {
    padding: 62px 20px 30px 20px;
  }
  #geography .geography__block {
    left: 20px;
  }
}
@media (max-width: 500px) {
  .hero .hero__container {
    padding-top: 128px;
  }
  .social .social__item {
    width: 40px;
    height: 40px;
  }
  .header .header__logo img {
    width: 160px;
  }
  #hero .hero__title {
    max-width: 100%;
    margin-bottom: 25px;
    font-size: 25px;
  }
  .hero__right .hero__video_img img {
    border-radius: 12px;
  }
  .hero .hero__video .hero__video_block {
    width: 65px;
    height: 65px;
  }
  .join form {
    max-width: 360px;
  }
  .consultation form {
    max-width: 360px;
  }
  .form-consult .form-consult__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .form-consult .form-consult__input {
    padding: 24px 60px;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }
  .form-consult .form-consult__text {
    font-size: 15px;
    line-height: 20px;
  }
  .form-consult .form-consult__option {
    flex-direction: column;
  }
  .form-consult .form-consult__btn label {
    font-size: 15px;
  }
  .form-consult .form-consult__footer {
    gap: 15px;
  }
  .form-consult .form-consult__footer .btn--submit {
    width: 280px;
    height: 79px;
    font-weight: 500;
    font-size: 15px;
  }
  .slider-directory .slider-directory__content {
    padding: 47px 29px 42px 29px;
  }
  .slider-directory .slider-directory__button .btn--more {
    width: 300px;
    font-size: 15px;
  }
  #directory .directory__title {
    font-size: 25px;
    line-height: 30px;
  }
  #directory {
    padding: 40px 0 30px 0 !important;
  }
  #project .project__title {
    font-size: 25px;
    line-height: 30px;
  }
  #project {
    padding: 40px 0 30px 0 !important;
  }
  .slider-project .slider-project__content {
    padding: 27px;
  }
  #project .project__container {
    padding: 0 15px;
  }
  #directory .directory__container {
    padding: 0 15px;
  }
  #reviews .reviews__container {
    padding: 0 15px;
  }
  #video .video__container {
    padding: 0 15px;
  }
  #gallery .gallery__imgg {
    min-height: 140px;
  }
  #consultation .consultation__container {
    padding: 0 15px;
  }
  #geography .geography__container {
    padding: 0 15px;
  }
  .reasons .reasons__cards {
    row-gap: 20px;
    column-gap: 20px;
  }
  .consultation .consultation__title {
    font-size: 20px;
    line-height: 24px;
  }
  .consultation .consultation__descr {
    font-size: 15px;
    line-height: 133.9%;
  }
  .consultation .consultation__name {
    font-size: 20px;
    line-height: 20px;
  }
  .consultation .consultation__job {
    font-size: 17px;
    line-height: 20px;
  }
  .consultation .consultation__content {
    gap: 20px;
  }
  .geography .geography__img {
    max-height: 185px;
  }
  .geography .geography__button .btn--geography {
    width: 230px;
    height: 63px;
    font-size: 14px;
  }
  .geography .geography__name {
    margin-bottom: 30px;
    font-size: 20px;
  }
  .geography .geography__items {
    gap: 11px;
  }
  #geography {
    padding-bottom: 70px !important;
  }
  .geography .geography__block {
    width: 65px;
    height: 65px;
    top: -32.5px;
    left: 38px;
  }
  .geography .geography__block img {
    width: 23px;
  }
  .footer .footer__gps {
    flex-direction: column;
    gap: 5px;
  }
  .footer .footer__descr {
    font-size: 13px;
  }
  .footer .footer__container {
    gap: 20px;
  }
  #footer {
    padding: 20px 0;
  }
}
@media (max-width: 768px) {
  #hero {
    padding-bottom: 113px;
  }
  .hero .hero__title {
    max-width: 590px;
    font-size: 37px;
    background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%), #272626;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-emphasis-color: transparent;
  }
  .hero .hero__benefits {
    padding-left: 0;
    margin-bottom: 0;
  }
  .benefits .benefits__text {
    font-size: 16px;
    max-width: 280px;
  }
  .hero__button .btn--pick {
    display: none;
  }
  .hero .hero__content {
    gap: 30px;
    align-items: center !important;
  }
  .hero .hero__right {
    padding-top: 0;
  }
  .hero .hero__buttonm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  section .section .section__title {
    font-size: 25px;
    line-height: 30px;
  }
  .quiz .quiz__block {
    background: #f1f1f1;
  }
  #quiz {
    padding-bottom: 50px;
  }
  .join form {
    padding: 32px 39px 42px 39px;
  }
  .consultation form {
    padding: 32px 39px 42px 39px;
  }
  .slider-video .slider-video__img {
    max-height: 650px;
  }
  .slider-video .slider-video__play_ico img {
    width: 80px;
    height: 80px;
  }
  .slider-project .slider-project__descr {
    font-size: 15px;
  }
  .slider-project .slider-project__descr:not(:last-child) {
    margin-bottom: 9px;
  }
  .slider-project .slider-project__name {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .slider-project .slider-project__info {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .slider-project .slider-project__button .btn--yes {
    width: 100%;
    height: 78px;
    font-weight: 500;
    font-size: 16px;
    background-size: contain;
    padding-bottom: 0;
  }
  .gallery .gallery__row {
    flex-direction: column;
  }
  .gallery .gallery__imgg {
    min-height: 250px;
  }
  .reasons .reasons__card--full {
    min-width: 316px;
  }
  .reasons .reasons__block {
    display: none;
  }
  .geography .geography__card {
    min-width: 100%;
    width: 100%;
  }
  .geography .geography__content {
    padding: 72px 49px 41px 39px;
  }
  .footer .footer__row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .footer .footer__location {
    align-items: center;
  }
  .footer .footer__link {
    text-align: center;
  }
}
@media (max-width: 968px) {
  .section .section__title {
    font-size: 36px;
    line-height: 40px;
  }
  .directory .directory__title {
    font-size: 34px;
    line-height: 46px;
  }
  .project .project__title {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 30px;
  }
  #project {
    margin-bottom: 60px;
    padding: 50px 0 70px 0;
  }
  #directory {
    margin-bottom: 60px;
    padding: 50px 0 70px 0;
  }
  #reviews {
    padding-bottom: 60px;
  }
  #join {
    padding-bottom: 60px;
  }
  #video {
    padding-bottom: 60px;
  }
  #certificate {
    padding-bottom: 60px;
  }
  #gallery {
    padding-bottom: 60px;
  }
  .gallery .gallery__row {
    gap: 7px;
  }
  .gallery .gallery__content {
    gap: 10px;
  }
  #reasons {
    padding-bottom: 60px;
  }
  #consultation {
    padding-bottom: 60px;
  }
  .consultation .consultation__right {
    gap: 30px;
  }
  .consultation .consultation__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .geography .geography__tree {
    display: none;
  }
}
@media (max-width: 1024px) {
  .header .header__info {
    display: none;
  }
  .header {
    position: fixed !important;
    background: #fff;
    box-shadow: -2px 1px 1px #a5a5a5;
    padding: 15px 0 !important;
  }
  .header .header__button {
    display: none;
  }
}
@media (min-width: 1025px) {
  .mobile {
    display: none;
  }
}
@media (max-width: 1215px) {
  #hero {
    height: 100%;
    min-height: 100%;
  }
  .hero .hero__content {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
  .slider-directory .slider-directory__slide {
    max-width: 100%;
  }
  .slider-directory .slider-directory__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider-directory .slider-directory__data {
    align-items: center;
  }
  .slider-project .slider-project__content {
    flex-direction: column;
  }
  .slider-project .slider-project__name {
    text-align: center;
  }
  .slider-project .slider-project__data {
    max-width: 100%;
  }
  .slider-certificate .slider-certificate__img {
    width: 100%;
  }
  .consultation .consultation__data {
    padding-left: 0;
  }
  .consultation .consultation__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1360px) {
  .join .join__content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .join .join__left {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }
  .join .join__img {
    min-width: 180px;
  }
  .join .join__column {
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: scroll;
    padding-bottom: 10px;
  }
  .join .join__column::-webkit-scrollbar {
    height: 4px;
    background: #bdbdbd;
  }
  .join .join__column::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #5AA54F;
    transition: 0.7s background;
  }
  .join .join__column::-webkit-scrollbar-thumb:hover {
    background: #437a3b;
  }
  .join .join__column--one {
    animation: none;
  }
  .join .join__column--two {
    animation: none;
  }
  .join .join__block:not(:last-child) {
    margin-bottom: 0px;
  }
  .slider-reviews .slider-reviews__slide {
    max-width: 100%;
  }
  .slider-video .slider-video__slide {
    max-width: 100%;
  }
  .slider-video .slider-video__img {
    width: 100%;
  }
}
@media (max-width: 1740px) {
  .quiz .section {
    margin-top: 3% !important;
  }
}
@media (min-height: 1100px) {
  .geography .geography__container {
    justify-content: center;
  }
}
@media (max-width: 1300px) {
  #geography {
    height: 100%;
    padding-bottom: 130px;
  }
}
:root {
  --font-family: "Montserrat";
  --content-width: 1310px;
  --container-offset: 35px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white-color: #fff;
  --black-color: #000;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/../fonts/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

body::-webkit-scrollbar {
  width: 6px;
  background: #bdbdbd;
}
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #5AA54F;
  transition: 0.7s background;
}
body::-webkit-scrollbar-thumb:hover {
  background: #437a3b;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

.section {
  text-align: center;
  margin-bottom: 60px;
}
.section__title {
  max-width: 1150px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 51.35%, rgba(255, 255, 255, 0) 83.81%), #4A4A4A;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}
.section__title span {
  font-weight: 600;
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%), linear-gradient(0deg, #4A4A4A, #4A4A4A), linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 51.35%, rgba(255, 255, 255, 0) 83.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.burger {
  --burger-width: 30px;
  --burger-height: 30px;
  --burger-line-height: 2px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: #000;
  background-color: transparent;
  cursor: pointer;
  width: 23px;
  height: 14px;
  z-index: 9999;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger::before {
  top: 0;
}
.burger::after {
  top: calc(100% - var(--burger-line-height));
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
.burger--active {
  color: #fff;
  position: fixed;
  right: 20px;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.header {
  padding: 27px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__info {
  display: flex;
  align-items: center;
  gap: 15px;
}
.header__info_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 92.9%;
  color: #999999;
  max-width: 135px;
}

.hero {
  height: 100vh;
  min-height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero__container {
  position: relative;
  padding-top: 143px;
}
.hero__content {
  display: flex;
  gap: 112px;
}
.hero__title {
  max-width: 695px;
  font-weight: 500;
  font-size: 48px;
  line-height: 133.9%;
  background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%), #4A4A4A;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
  margin-bottom: 35px;
}
.hero__title span {
  font-weight: 600;
  background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%), #204B9B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}
.hero__right {
  padding-top: 40px;
}
.hero__buttonm {
  display: none;
}
.hero__video {
  position: relative;
}
.hero__video_img {
  display: block;
}
.hero__video_img img {
  display: block;
  border-radius: 33px;
}
.hero__video_block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%), #5AA54F;
  box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25), inset 0px -3px 12px rgba(255, 255, 255, 0.35);
  border-radius: 100%;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transition: 0.3s left ease-in-out;
}
.hero__video_block:hover {
  left: 45%;
}
.hero__video_icon {
  margin-left: 4.5px;
  width: 31px;
  height: 31px;
  fill: var(--white-color);
}

.quiz {
  padding-bottom: 145px;
}
.quiz__block {
  max-width: 1140px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}

.quiz .section {
  margin-top: -3.5%;
}

.join {
  padding-bottom: 130px;
}
.join__content {
  display: flex;
  gap: 109px;
}
.join__left {
  display: flex;
  gap: 57px;
}
.join__img {
  display: block;
  border-radius: 28px;
  transition: 0.3s transform ease-in-out;
}
.join__img:hover {
  transform: scale(1.2);
}
.join__block {
  border-radius: 28px;
  overflow: hidden;
}
.join__block:not(:last-child) {
  margin-bottom: 20px;
}

.directory {
  padding: 73px 0 81px 0;
  background-image: url("../img/bg-directory.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 130px;
}
.directory__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: var(--white-color);
  margin-bottom: 70px;
}

.reviews {
  padding-bottom: 130px;
}

.video {
  padding-bottom: 130px;
}

.project {
  padding: 73px 0 81px 0;
  background-image: url("../img/bg-project.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 130px;
}
.project__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  color: var(--white-color);
  margin-bottom: 70px;
}
.project__container {
  max-width: 1240px;
}

.certificate {
  padding-bottom: 130px;
}

.gallery {
  padding-bottom: 130px;
}
.gallery__row {
  display: flex;
  align-items: center;
  gap: 20px;
}
.gallery__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.gallery__img {
  border-radius: 25px;
  transition: 0.3s transform ease-in-out;
}
.gallery__img:hover {
  transform: scale(1.2);
}
.gallery__block {
  border-radius: 25px;
  overflow: hidden;
}
.gallery__imgg {
  border-radius: 25px;
  transition: 0.3s transform ease-in-out;
}
.gallery__imgg:hover {
  transform: scale(1.2);
}

.reasons {
  padding-bottom: 130px;
}
.reasons__cards {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  row-gap: 37px;
  column-gap: 37px;
  max-width: 1030px;
  margin: 0 auto;
}
.reasons__card {
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  border-radius: 12px;
  max-width: 316px;
}
.reasons__card--full {
  min-width: 672px;
  position: relative;
  overflow: hidden;
}
.reasons__block {
  position: absolute;
  right: -70px;
  top: -40px;
}
.reasons__content {
  padding: 45px 20px 20px 45px;
  height: 100%;
}
.reasons__name {
  font-weight: 700;
  font-size: 29px;
  line-height: 168.6%;
  color: var(--white-color);
  margin-bottom: 25px;
}
.reasons__descr {
  font-weight: 400;
  font-size: 18px;
  line-height: 125.1%;
  color: var(--white-color);
  max-width: 380px;
}

.consultation {
  padding-bottom: 80px;
}
.consultation__content {
  display: flex;
  gap: 58px;
}
.consultation__title {
  max-width: 386px;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: var(--black-color);
  margin-bottom: 38px;
}
.consultation__title span {
  font-weight: 500;
}
.consultation__items {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-bottom: 35px;
}
.consultation__item {
  display: flex;
  align-items: center;
  gap: 19px;
}
.consultation__block {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  vertical-align: middle;
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
}
.consultation__descr {
  max-width: 324px;
  font-weight: 500;
  font-size: 18px;
  line-height: 133.9%;
  color: var(--black-color);
}
.consultation__person {
  overflow: hidden;
  border-radius: 16px;
}
.consultation__img {
  border-radius: 16px;
  transition: 0.3s transform ease-in-out;
}
.consultation__img:hover {
  transform: scale(1.2);
}
.consultation__right {
  display: flex;
  flex-direction: column;
  gap: 118px;
}
.consultation__data {
  padding-left: 80px;
}
.consultation__name {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--black-color);
}
.consultation__job {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: var(--black-color);
}
.consultation__job span {
  font-weight: 700;
}

.geography {
  background-image: url("../img/bg-geography.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 120vh;
  position: relative;
}
.geography__tree {
  position: absolute;
  left: 0;
  top: -7%;
}
.geography__bushes {
  position: absolute;
  bottom: 0;
}
.geography__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.geography__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1225px;
  row-gap: 21px;
  column-gap: 35px;
}
.geography__item {
  overflow: hidden;
  border-radius: 16px;
}
.geography__card {
  min-width: 513px;
  background: #FFFFFF;
  box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.13);
  border-radius: 16px;
  position: relative;
}
.geography__content {
  padding: 100px 0 59px 55px;
}
.geography__name {
  max-width: 384px;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: -0.015em;
  color: var(--black-color);
  margin-bottom: 45px;
}
.geography__block {
  position: absolute;
  top: -46.5px;
  left: 55px;
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  box-shadow: 0px 27px 38px rgba(38, 84, 164, 0.4);
  border-radius: 13px;
  width: 93px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.geography__img {
  border-radius: 16px;
  transition: 0.3s transform ease-in-out;
}
.geography__img:hover {
  transform: scale(1.2);
}

.footer {
  background: #434343;
  padding: 50px 0;
}
.footer__container {
  display: flex;
  flex-direction: column;
  gap: 55px;
}
.footer__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__location {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.footer__gps {
  display: flex;
  align-items: center;
  gap: 14px;
}
.footer__text {
  font-weight: 500;
  font-size: 15px;
  line-height: 178.1%;
  color: #B9B6B6;
}
.footer__descr {
  font-weight: 600;
  font-size: 15px;
  color: var(--white-color);
}
.footer__info {
  display: flex;
  flex-direction: column;
}
.footer__phone {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer__phone_link {
  color: var(--white-color);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.footer__phone_job {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--white-color);
  width: 90px;
  text-align: right;
  margin-left: auto;
  position: relative;
}
.footer__phone_job::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(92.06deg, #7EC05F 4.57%, #89CD68 98.91%);
  width: 3px;
  height: 3px;
}
.footer__link {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--white-color);
  transition: 0.3s color ease-in-out;
}
.footer__link:hover {
  color: silver;
}
.footer__copyright {
  color: var(--white-color);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.phone {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px dashed #000000;
  border-radius: 41px;
  padding: 14px 16px;
  position: relative;
  transition: 0.3s top ease-in-out;
}
.phone:hover::after {
  top: 36%;
}
.phone::after {
  content: "";
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: linear-gradient(71.7deg, rgba(255, 255, 255, 0) 19.71%, rgba(255, 255, 255, 0.28) 89.97%, rgba(255, 255, 255, 0) 166.6%), #5AA54F;
  transition: 0.3s top ease-in-out;
}
.phone__icon {
  width: 12px;
  height: 12px;
  fill: #4A4A4A;
}
.phone__link {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  background: linear-gradient(93.35deg, rgba(255, 255, 255, 0) 19.5%, rgba(255, 255, 255, 0.47) 60%, rgba(255, 255, 255, 0) 98.11%), #4A4A4A;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}

.social {
  display: flex;
  align-items: center;
  gap: 12px;
}
.social__item {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.social__item--whatsapp {
  background: linear-gradient(166.15deg, #1CE218 9.89%, #039D00 94.68%);
  box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25), inset 0px -3px 12px rgba(255, 255, 255, 0.35);
}
.social__item--tg {
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25), inset 0px -3px 12px rgba(255, 255, 255, 0.35);
}
.social__icon--whatsapp {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  fill: var(--white-color);
}
.social__icon--tg {
  margin-top: 5.5px;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  fill: var(--white-color);
}

.btn--zakaz {
  background-image: url("../img/btn-zakaz.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 286px;
  height: 59px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--zakaz:hover {
  transform: translateX(-5px);
}
.btn--pick {
  background-image: url("../img/btn-pick.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 428px;
  height: 120px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--pick:hover {
  transform: translateX(-5px);
}
.btn--pickm {
  background-image: url("../img/btn-pickm.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 282px;
  height: 79px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--pickm:hover {
  transform: translateX(-5px);
}
.btn--submit {
  background-image: url("../img/btn-pickm.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 428px;
  height: 121px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--submit:hover {
  transform: translateX(-5px);
}
.btn--more {
  background-image: url("../img/btn-more.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 326px;
  height: 82.2px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888988;
  text-transform: uppercase;
  transition: 0.3s background-image ease-in-out, 0.3s color ease-in-out, 0.3s height ease-in-out;
}
.btn--more:hover {
  background-image: url("../img/btn-morehover.webp");
  color: var(--white-color);
}
.btn--yes {
  background-image: url("../img/btn--yes.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 326px;
  height: 86.3px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--yes:hover {
  transform: translateX(-5px);
}
.btn--geography {
  background-image: url("../img/btn-geography.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 327px;
  height: 88px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s transform ease-in-out;
}
.btn--geography:hover {
  transform: translateX(-5px);
}

.location {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 58px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  max-width: 290px;
  gap: 11px;
  margin-bottom: 20px;
}
.location__text {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: var(--black-color);
}
.location__block {
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  border-radius: 13px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 40px;
  margin-bottom: 43px;
}
.benefits__item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.benefits__block {
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.benefits__icon--star {
  width: 22px;
  height: 22px;
  fill: var(--white-color);
}
.benefits__icon--security {
  width: 22px;
  height: 22px;
  fill: var(--white-color);
}
.benefits__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 133.9%;
  color: #777777;
  max-width: 465px;
}
.benefits__text span {
  font-weight: 600;
  color: var(--black-color);
}

.mobile div {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  height: 49px;
  width: 31px;
  z-index: 9999999;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 3px;
  background-color: #1C1C1C;
  border-radius: 13px;
  transition-duration: 0.4s;
}

.mobile div span:nth-child(1) {
  top: 14px;
}

.mobile div span:nth-child(2) {
  top: 23px;
}

.mobile div span:nth-child(3) {
  bottom: 14px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-15px, 9px) rotate(-45deg);
  background-color: #fff;
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
  background-color: #fff;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-15px, -9px) rotate(45deg);
  background-color: #fff;
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background: linear-gradient(32.89deg, rgba(255, 255, 255, 0) 52.3%, rgba(255, 255, 255, 0.28) 95.89%, rgba(255, 255, 255, 0) 110.31%), #5AA54F;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.12);
  width: 100vw;
  height: 100vh;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 28px;
}

.mobile ul li a {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 23px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.form-consult {
  background: #FBFBFB;
  box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  max-width: 550px;
  padding: 77px 55px 60px 55px;
}
.form-consult__title {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #1C1C1C;
  margin-bottom: 35px;
}
.form-consult__title span {
  font-weight: 600;
}
.form-consult__input {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1C1C1C;
  padding: 36px 30px 36px 60px;
  border: none;
  width: 100%;
  background: #F5F5F5;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.form-consult__input:not(:last-child) {
  margin-bottom: 20px;
}
.form-consult__input::placeholder {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: rgba(28, 28, 28, 0.43);
}
.form-consult__select {
  margin-top: 8px;
}
.form-consult__text {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(87, 103, 118, 0.6);
  margin-bottom: 10px;
}
.form-consult__option {
  display: flex;
  align-items: center;
  gap: 12px;
}
.form-consult__btn {
  display: inline-block;
  /* Checked */
  /* Hover */
}
.form-consult__btn input[type=radio] {
  display: none;
}
.form-consult__btn label {
  font-family: "Gilroy";
  cursor: pointer;
  user-select: none;
  background: #FFFFFF;
  border-radius: 100px;
  padding: 7px 24px 7px 9px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #576776;
  transition: 0.3s background ease-in-out, 0.3s color ease-in-out;
}
.form-consult__btn input[type=radio]:checked + label {
  background: #4F4F4F;
  color: #fff;
}
.form-consult__btn label:hover {
  color: #fff;
  background: #4F4F4F;
}
.form-consult__footer {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.form-consult__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.form-consult__checkbox + label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  gap: 22px;
}
.form-consult__checkbox + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #009789;
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.form-consult__checkbox:not(:disabled):active + label::before {
  background-color: #009789;
  border-color: #009789;
}
.form-consult__checkbox:checked + label::before {
  border-color: #009789;
  background-color: #009789;
  background-image: url("../img/svg/arrowform-ico.svg");
  background-size: 14px;
}
.form-consult__sms {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--black-color);
}
.form-consult__link {
  color: var(--black-color);
  text-decoration-line: underline;
}

.slider-directory {
  max-width: 1210px;
  padding-bottom: 58px;
}
.slider-directory__slide {
  background: #FFFFFF;
  border-radius: 15px;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.slider-directory__img {
  display: block;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
}
.slider-directory__content {
  padding: 50px 32px 46px 32px;
}
.slider-directory__name {
  font-weight: 600;
  font-size: 22px;
  line-height: 139.9%;
  color: #777877;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 22px;
}
.slider-directory__data {
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-bottom: 43px;
}
.slider-directory__count {
  font-weight: 500;
  font-size: 15px;
  line-height: 139.9%;
  color: rgba(0, 0, 0, 0.5);
}
.slider-directory__button {
  height: auto;
}
.slider-reviews {
  padding-bottom: 66px;
}
.slider-reviews__slide {
  background: var(--white-color);
  box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  max-width: 424px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.slider-reviews__img {
  border-radius: 100%;
}
.slider-reviews__content {
  padding: 34px 35px 30px 35px;
}
.slider-reviews__block {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 39px;
}
.slider-reviews__name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--black-color);
}
.slider-reviews__city {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: var(--black-color);
}
.slider-reviews__descr {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--black-color);
  max-width: 339px;
  margin-bottom: 64px;
}
.slider-reviews__date {
  font-size: 17px;
  line-height: 21px;
  color: #B4B4B4;
}
.slider-video {
  padding-bottom: 66px;
}
.slider-video__slide {
  max-width: 412px;
  position: relative;
}
.slider-video__img {
  border-radius: 12px;
}
.slider-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s left ease-in-out;
}
.slider-video__play:hover {
  left: 47%;
}
.slider-project {
  padding-bottom: 66px;
}
.slider-project__slide {
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}
.slider-project__content {
  padding: 59px 75px;
  display: flex;
  align-items: center;
  gap: 50px;
}
.slider-project__gallery {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.slider-project__row {
  display: flex;
  align-items: center;
  gap: 21px;
}
.slider-project__img {
  border-radius: 15px;
}
.slider-project__data {
  max-width: 447px;
}
.slider-project__name {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--black-color);
  margin-bottom: 36px;
}
.slider-project__descr {
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #595959;
}
.slider-project__descr:not(:last-child) {
  margin-bottom: 14px;
}
.slider-project__info {
  margin: 43px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.slider-project__time {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #595959;
}
.slider-project__time span {
  font-weight: 400;
}
.slider-project__price {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #595959;
}
.slider-project__price span {
  font-weight: 400;
}
.slider-certificate__img {
  border-radius: 13px;
}

.swiper-pagination-bullet-active {
  border: medium none;
  opacity: 1;
  width: 205px !important;
}

.slider-directory .swiper-pagination-bullet-active {
  background: var(--white-color);
}

.slider-project .swiper-pagination-bullet-active {
  background: var(--white-color);
}

.slider-reviews .swiper-pagination-bullet-active {
  background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%), #5AA54F;
}

.slider-video .swiper-pagination-bullet-active {
  background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%), #5AA54F;
}

.swiper-pagination-bullet {
  width: 92px;
  height: 14px;
  border-radius: 43px;
  background: #525252;
  transition: 0.3s width ease-in-out, 0.3s height ease-in-out;
  opacity: 1;
}