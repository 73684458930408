.mobile div {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  height: 49px;
  width: 31px;
  z-index: 9999999;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 3px;
  background-color: #1C1C1C;
  border-radius: 13px;
  transition-duration: 0.4s;
}

.mobile div span:nth-child(1) {
  top: 14px;
}

.mobile div span:nth-child(2) {
  top: 23px;
}

.mobile div span:nth-child(3) {
  bottom: 14px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-15px, 9px) rotate(-45deg);
  background-color: #fff;
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
  background-color: #fff;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-15px, -9px) rotate(45deg);
  background-color: #fff;
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background: linear-gradient(32.89deg, rgba(255, 255, 255, 0) 52.3%, rgba(255, 255, 255, 0.28) 95.89%, rgba(255, 255, 255, 0) 110.31%),
    #5AA54F;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.12);
  width: 100vw;
  height: 100vh;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 28px;
}

.mobile ul li a {
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 23px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

@keyframes fadein {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;
  }
}
