.hero {
  height: 100vh;
  min-height: 900px;
  background-size: cover;
  background-repeat: no-repeat;

  &__container {
    position: relative;
    padding-top: calc(113px + 30px);
  }

  &__content {
    display: flex;
    gap: 112px;
  }

  &__title {
    max-width: 695px;
    font-weight: 500;
    font-size: 48px;
    line-height: 133.9%;
    background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%),
      #4A4A4A;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-emphasis-color: transparent;
    margin-bottom: 35px;

    span {
      font-weight: 600;
      background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%),
        #204B9B;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-emphasis-color: transparent;
    }
  }

  &__right {
    padding-top: 40px;
  }

  &__buttonm {
    display: none;
  }

  &__video {
    position: relative;

    &_img {
      display: block;

      img {
        display: block;
        border-radius: 33px;
      }
    }

    &_block {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%),
        #5AA54F;
      box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25),
        inset 0px -3px 12px rgba(255, 255, 255, 0.35);
      border-radius: 100%;
      width: 75px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      transition: .3s left ease-in-out;

      &:hover {
        left: 45%;
      }
    }

    &_icon {
      margin-left: 4.5px;
      width: 31px;
      height: 31px;
      fill: var(--white-color);
    }
  }
}
