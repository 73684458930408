.benefits {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 40px;
  margin-bottom: 43px;

  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__block {
    width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  &__icon {

    &--star {
      width: 22px;
      height: 22px;
      fill: var(--white-color);
    }

    &--security {
      width: 22px;
      height: 22px;
      fill: var(--white-color);
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 133.9%;
    color: #777777;
    max-width: 465px;

    span {
      font-weight: 600;
      color: var(--black-color);
    }
  }
}
