// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Montserrat";
  --content-width: 1310px;
  --container-offset: 35px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --white-color: #fff;
  --black-color: #000;
}
