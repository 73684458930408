.slider {

  &-directory {
    max-width: 1210px;
    padding-bottom: 58px;

    &__slide {
      background: #FFFFFF;
      border-radius: 15px;
      max-width: 390px;
      display: flex;
      flex-direction: column;
      height: auto;
    }

    &__img {
      display: block;
      border-radius: 16px 16px 0px 0px;
      width: 100%;
    }

    &__content {
      padding: 50px 32px 46px 32px;
    }

    &__name {
      font-weight: 600;
      font-size: 22px;
      line-height: 139.9%;
      color: #777877;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 22px;
    }

    &__data {
      display: flex;
      flex-direction: column;
      gap: 9px;
      margin-bottom: 43px;
    }

    &__count {
      font-weight: 500;
      font-size: 15px;
      line-height: 139.9%;
      color: rgba(#000, 0.5);
    }

    &__button {
      height: auto;
    }
  }

  &-reviews {
    padding-bottom: 66px;

    &__slide {
      background: var(--white-color);
      box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.05);
      border-radius: 9px;
      max-width: 424px;
      display: flex;
      flex-direction: column;
      height: auto;
    }

    &__img {
      border-radius: 100%;
    }

    &__content {
      padding: 34px 35px 30px 35px;
    }

    &__block {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 39px;
    }

    &__name {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: var(--black-color);
    }

    &__city {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      color: var(--black-color);
    }

    &__descr {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--black-color);
      max-width: 339px;
      margin-bottom: 64px;
    }

    &__date {
      font-size: 17px;
      line-height: 21px;
      color: #B4B4B4;
    }
  }

  &-video {
    padding-bottom: 66px;

    &__slide {
      max-width: 412px;
      position: relative;
    }

    &__img {
      border-radius: 12px;
    }

    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .3s left ease-in-out;

      &:hover {
        left: 47%;
      }
    }
  }

  &-project {
    padding-bottom: 66px;

    &__slide {
      background: #FFFFFF;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
    }

    &__content {
      padding: 59px 75px;
      display: flex;
      align-items: center;
      gap: 50px;
    }

    &__gallery {
      display: flex;
      flex-direction: column;
      gap: 21px;
    }

    &__row {
      display: flex;
      align-items: center;
      gap: 21px;
    }

    &__img {
      border-radius: 15px;
    }

    &__data {
      max-width: 447px;
    }

    &__name {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: var(--black-color);
      margin-bottom: 36px;
    }

    &__descr {
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
      color: #595959;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    &__info {
      margin: 43px 0;
      display: flex;
      flex-direction: column;
      gap: 7px;
    }

    &__time {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #595959;

      span {
        font-weight: 400;
      }
    }

    &__price {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #595959;

      span {
        font-weight: 400;
      }
    }
  }

  &-certificate {

    &__img {
      border-radius: 13px;
    }
  }
}

.swiper-pagination-bullet-active {
  border: medium none;
  opacity: 1;
  width: 205px !important;
}

.slider-directory .swiper-pagination-bullet-active {
  background: var(--white-color);
}

.slider-project .swiper-pagination-bullet-active {
  background: var(--white-color);
}

.slider-reviews .swiper-pagination-bullet-active {
  background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%),
    #5AA54F;
}

.slider-video .swiper-pagination-bullet-active {
  background: linear-gradient(103.7deg, rgba(255, 255, 255, 0) 13.97%, rgba(255, 255, 255, 0.28) 46.72%, rgba(255, 255, 255, 0) 82.44%),
    #5AA54F;
}

.swiper-pagination-bullet {
  width: 92px;
  height: 14px;
  border-radius: 43px;
  background: #525252;
  transition: .3s width ease-in-out, .3s height ease-in-out;
  opacity: 1;
}
