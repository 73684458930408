.quiz {
  padding-bottom: 145px;

  &__block {
    max-width: 1140px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
  }
}

.quiz .section {
  margin-top: -3.5%;
}
