.gallery {
  padding-bottom: 130px;

  &__row {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__img {
    border-radius: 25px;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__block {
    border-radius: 25px;
    overflow: hidden;
  }

  &__imgg {
    border-radius: 25px;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }
}
