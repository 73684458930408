@media (max-width: 320px) {
  .header .header__logo img {
    width: 120px !important;
  }
}

@media (max-width: 425px) {
  #hero {
    background-size: auto;
    padding-bottom: 70px !important;
  }

  #hero .hero__title {
    font-size: 21px !important;
  }

  .benefits .benefits__text {
    font-size: 14px !important;
    max-width: 100% !important;
    color: #000000;
    text-align: center;
  }

  .benefits .benefits__item {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .hero .hero__video .hero__video_block {
    width: 55px !important;
    height: 55px !important;
  }

  .hero .hero__video .hero__video_icon {
    width: 25px;
    height: 25px;
  }

  .header .header__social {
    display: none;
  }

  section .section .section__title {
    font-size: 21px !important;
    line-height: 25px !important;
  }

  .form-consult .form-consult__text {
    font-size: 14px !important;
  }

  .form-consult .form-consult__footer .btn--submit {
    width: 226px !important;
    height: 64px !important;
    font-size: 12px !important;
  }

  .form-consult .form-consult__input {
    padding: 17px 60px !important;
    font-size: 11px !important;
  }

  .form-consult .form-consult__sms {
    font-size: 11px;
  }

  .slider-directory .slider-directory__count {
    font-size: 12px;
  }

  .slider-directory .slider-directory__name {
    font-size: 18px;
  }

  .slider-directory .slider-directory__button .btn--more {
    width: 240px !important;
    font-size: 12px !important;
    padding-bottom: 0;
  }

  .slider-directory .slider-directory__content {
    padding: 20px 0 !important;
  }

  #directory .directory__title {
    font-size: 21px !important;
    line-height: 27px !important;
  }

  #project .project__title {
    font-size: 21px !important;
    line-height: 27px !important;
  }

  .slider-reviews .slider-reviews__descr {
    font-size: 13px;
  }

  .slider-reviews .slider-reviews__name {
    font-size: 15px;
  }

  .slider-reviews .slider-reviews__city {
    font-size: 14px;
  }

  .slider-reviews .slider-reviews__img {
    width: 57px;
    height: 57px;
  }

  .slider-reviews .slider-reviews__content {
    padding: 13px 20px;
  }

  .slider-project .slider-project__content {
    padding: 14px !important;
    gap: 20px;
  }

  .slider-project .slider-project__name {
    font-size: 17px !important;
    line-height: 17px !important;
  }

  .slider-project .slider-project__descr {
    font-size: 14px !important;

    &:not(:last-child) {
      margin-bottom: 0 !important;
    }
  }

  .slider-project .slider-project__time {
    font-size: 15px;
  }

  .slider-project .slider-project__price {
    font-size: 15px;
  }

  .slider-project .slider-project__info {
    margin-top: 10px !important;
    gap: 2px;
    margin-bottom: 15px !important;
  }

  .swiper-pagination-bullet {
    width: 60px !important;
  }

  #reasons .reasons__card--full {
    min-width: 100%;
  }

  .reasons .reasons__card {
    max-width: 100%;
  }

  .reasons .reasons__name {
    font-size: 26px;
    line-height: 127.6%;
    margin-bottom: 15px;
  }

  .reasons .reasons__descr {
    font-size: 15px;
    line-height: 113.1%;
  }

  .reasons .reasons__content {
    padding: 35px;
  }

  .consultation .consultation__item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .consultation .consultation__descr {
    text-align: center;
    max-width: 100%;
  }

  #consultation .consultation__name {
    font-size: 17px;
    line-height: 17px;
  }

  #consultation .consultation__job {
    font-size: 14px;
    line-height: 15px;
  }

  #geography .geography__content {
    padding: 62px 20px 30px 20px;
  }

  #geography .geography__block {
    left: 20px;
  }

}

@media (max-width: 500px) {

  .hero .hero__container {
    padding-top: calc(103px + 25px);
  }

  .social .social__item {
    width: 40px;
    height: 40px;
  }

  .header .header__logo img {
    width: 160px;
  }

  #hero .hero__title {
    max-width: 100%;
    margin-bottom: 25px;
    font-size: 25px;
  }

  .hero__right .hero__video_img img {
    border-radius: 12px;
  }

  .hero .hero__video .hero__video_block {
    width: 65px;
    height: 65px;
  }

  .join form {
    max-width: 360px;
  }

  .consultation form {
    max-width: 360px;
  }

  .form-consult .form-consult__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .form-consult .form-consult__input {
    padding: 24px 60px;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }

  .form-consult .form-consult__text {
    font-size: 15px;
    line-height: 20px;
  }

  .form-consult .form-consult__option {
    flex-direction: column;
  }

  .form-consult .form-consult__btn label {
    font-size: 15px;
  }

  .form-consult .form-consult__footer {
    gap: 15px;
  }

  .form-consult .form-consult__footer .btn--submit {
    width: 280px;
    height: 79px;
    font-weight: 500;
    font-size: 15px;
  }

  .slider-directory .slider-directory__content {
    padding: 47px 29px 42px 29px;
  }

  .slider-directory .slider-directory__button .btn--more {
    width: 300px;
    font-size: 15px;
  }

  #directory .directory__title {
    font-size: 25px;
    line-height: 30px;
  }

  #directory {
    padding: 40px 0 30px 0 !important;
  }

  #project .project__title {
    font-size: 25px;
    line-height: 30px;
  }

  #project {
    padding: 40px 0 30px 0 !important;
  }

  .slider-project .slider-project__content {
    padding: 27px;
  }

  #project .project__container {
    padding: 0 15px;
  }

  #directory .directory__container {
    padding: 0 15px;
  }

  #reviews .reviews__container {
    padding: 0 15px;
  }

  #video .video__container {
    padding: 0 15px;
  }

  #gallery .gallery__imgg {
    min-height: 140px;
  }

  #consultation .consultation__container {
    padding: 0 15px;
  }

  #geography .geography__container {
    padding: 0 15px;
  }

  .reasons .reasons__cards {
    row-gap: 20px;
    column-gap: 20px;
  }

  .consultation .consultation__title {
    font-size: 20px;
    line-height: 24px;
  }

  .consultation .consultation__descr {
    font-size: 15px;
    line-height: 133.9%;
  }

  .consultation .consultation__name {
    font-size: 20px;
    line-height: 20px;
  }

  .consultation .consultation__job {
    font-size: 17px;
    line-height: 20px;
  }

  .consultation .consultation__content {
    gap: 20px;
  }

  .geography .geography__img {
    max-height: 185px;
  }

  .geography .geography__button .btn--geography {
    width: 230px;
    height: 63px;
    font-size: 14px;
  }

  .geography .geography__name {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .geography .geography__items {
    gap: 11px;
  }

  #geography {
    padding-bottom: 70px !important;
  }

  .geography .geography__block {
    width: 65px;
    height: 65px;
    top: -32.5px;
    left: 38px;

    img {
      width: 23px;
    }
  }

  .footer .footer__gps {
    flex-direction: column;
    gap: 5px;
  }

  .footer .footer__descr {
    font-size: 13px;
  }

  .footer .footer__container {
    gap: 20px;
  }

  #footer {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  #hero {
    padding-bottom: 113px;
  }

  .hero .hero__title {
    max-width: 590px;
    font-size: 37px;
    background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 41.79%, rgba(255, 255, 255, 0) 83.81%),
      #272626;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-emphasis-color: transparent;
  }

  .hero .hero__benefits {
    padding-left: 0;
    margin-bottom: 0;
  }

  .benefits .benefits__text {
    font-size: 16px;
    max-width: 280px;
  }

  .hero__button .btn--pick {
    display: none;
  }

  .hero .hero__content {
    gap: 30px;
    align-items: center !important;
  }

  .hero .hero__right {
    padding-top: 0;
  }

  .hero .hero__buttonm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  section .section .section__title {
    font-size: 25px;
    line-height: 30px;
  }

  .quiz .quiz__block {
    background: #f1f1f1;
  }

  #quiz {
    padding-bottom: 50px;
  }

  .join form {
    padding: 32px 39px 42px 39px;
  }

  .consultation form {
    padding: 32px 39px 42px 39px;
  }

  .slider-video .slider-video__img {
    max-height: 650px;
  }

  .slider-video .slider-video__play_ico img {
    width: 80px;
    height: 80px;
  }

  .slider-project .slider-project__descr {
    font-size: 15px;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  .slider-project .slider-project__name {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .slider-project .slider-project__info {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .slider-project .slider-project__button .btn--yes {
    width: 100%;
    height: 78px;
    font-weight: 500;
    font-size: 16px;
    background-size: contain;
    padding-bottom: 0;
  }

  .gallery .gallery__row {
    flex-direction: column;
  }

  .gallery .gallery__imgg {
    min-height: 250px;
  }

  .reasons .reasons__card--full {
    min-width: 316px;
  }

  .reasons .reasons__block {
    display: none;
  }

  .geography .geography__card {
    min-width: 100%;
    width: 100%;
  }

  .geography .geography__content {
    padding: 72px 49px 41px 39px;
  }

  .footer .footer__row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .footer .footer__location {
    align-items: center;
  }

  .footer .footer__link {
    text-align: center;
  }
}

@media (max-width: 968px) {
  .section .section__title {
    font-size: 36px;
    line-height: 40px;
  }

  .directory .directory__title {
    font-size: 34px;
    line-height: 46px;
  }

  .project .project__title {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 30px;
  }

  #project {
    margin-bottom: 60px;
    padding: 50px 0 70px 0;
  }

  #directory {
    margin-bottom: 60px;
    padding: 50px 0 70px 0;
  }

  #reviews {
    padding-bottom: 60px;
  }

  #join {
    padding-bottom: 60px;
  }

  #video {
    padding-bottom: 60px;
  }

  #certificate {
    padding-bottom: 60px;
  }

  #gallery {
    padding-bottom: 60px;
  }

  .gallery .gallery__row {
    gap: 7px;
  }

  .gallery .gallery__content {
    gap: 10px;
  }

  #reasons {
    padding-bottom: 60px;
  }

  #consultation {
    padding-bottom: 60px;
  }

  .consultation .consultation__right {
    gap: 30px;
  }

  .consultation .consultation__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .geography .geography__tree {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header .header__info {
    display: none;
  }

  .header {
    position: fixed !important;
    background: #fff;
    box-shadow: -2px 1px 1px #a5a5a5;
    padding: 15px 0 !important;
  }

  .header .header__button {
    display: none;
  }
}

@media (min-width: 1025px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 1215px) {
  #hero {
    height: 100%;
    min-height: 100%;
  }

  .hero .hero__content {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }

  .slider-directory .slider-directory__slide {
    max-width: 100%;
  }

  .slider-directory .slider-directory__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-directory .slider-directory__data {
    align-items: center;
  }

  .slider-project .slider-project__content {
    flex-direction: column;
  }

  .slider-project .slider-project__name {
    text-align: center;
  }

  .slider-project .slider-project__data {
    max-width: 100%;
  }

  .slider-certificate .slider-certificate__img {
    width: 100%;
  }

  .consultation .consultation__data {
    padding-left: 0;
  }

  .consultation .consultation__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1360px) {
  .join .join__content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .join .join__left {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }

  .join .join__img {
    min-width: 180px;
  }

  .join .join__column {
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: scroll;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 4px;
      background: #bdbdbd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #5AA54F;
      transition: .7s background;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #437a3b;
    }
  }

  .join .join__column--one {
    animation: none;
  }

  .join .join__column--two {
    animation: none;
  }

  .join .join__block:not(:last-child) {
    margin-bottom: 0px;
  }

  .slider-reviews .slider-reviews__slide {
    max-width: 100%;
  }

  .slider-video .slider-video__slide {
    max-width: 100%;
  }

  .slider-video .slider-video__img {
    width: 100%;
  }
}

@media (max-width: 1740px) {
  .quiz .section {
    margin-top: 3% !important;
  }
}

@media (min-height: 1100px) {
  .geography .geography__container {
    justify-content: center;
  }
}

@media (max-width: 1300px) {
  #geography {
    height: 100%;
    padding-bottom: 130px;
  }
}
