.form {

  &-consult {
    background: #FBFBFB;
    box-shadow: 0px 4px 111px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    max-width: 550px;
    padding: 77px 55px 60px 55px;

    &__title {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #1C1C1C;
      margin-bottom: 35px;

      span {
        font-weight: 600;
      }
    }

    &__input {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #1C1C1C;
      padding: 36px 30px 36px 60px;
      border: none;
      width: 100%;
      background: #F5F5F5;
      mix-blend-mode: normal;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &::placeholder {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        color: rgba(#1C1C1C, 0.43);
      }
    }

    &__select {
      margin-top: 8px;
    }

    &__text {
      font-family: 'Gilroy';
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: rgba(#576776, 0.6);
      margin-bottom: 10px;
    }

    &__option {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__btn {
      display: inline-block;

      & input[type=radio] {
        display: none;
      }

      & label {
        font-family: 'Gilroy';
        cursor: pointer;
        user-select: none;
        background: #FFFFFF;
        border-radius: 100px;
        padding: 7px 24px 7px 9px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #576776;
        transition: .3s background ease-in-out, .3s color ease-in-out;
      }

      /* Checked */
      & input[type=radio]:checked+label {
        background: #4F4F4F;
        color: #fff;
      }

      /* Hover */
      & label:hover {
        color: #fff;
        background: #4F4F4F;
      }
    }

    &__footer {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &+label {
        display: inline-flex;
        align-items: flex-start;
        user-select: none;
        gap: 22px;
      }

      &+label::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #009789;
        border-radius: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }

      &:not(:disabled):active+label::before {
        background-color: #009789;
        border-color: #009789;
      }

      &:checked+label::before {
        border-color: #009789;
        background-color: #009789;
        background-image: url("../img/svg/arrowform-ico.svg");
        background-size: 14px;
      }
    }

    &__sms {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--black-color);
    }

    &__link {
      color: var(--black-color);
      text-decoration-line: underline;
    }
  }
}
