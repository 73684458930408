.btn {

  &--zakaz {
    background-image: url('../img/btn-zakaz.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 286px;
    height: 59px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &--pick {
    background-image: url('../img/btn-pick.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 428px;
    height: 120px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &--pickm {
    background-image: url('../img/btn-pickm.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 282px;
    height: 79px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &--submit {
    background-image: url('../img/btn-pickm.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 428px;
    height: 121px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &--more {
    background-image: url('../img/btn-more.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 326px;
    height: 82.2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888988;
    text-transform: uppercase;
    transition: .3s background-image ease-in-out, .3s color ease-in-out, .3s height ease-in-out;

    &:hover {
      background-image: url('../img/btn-morehover.webp');
      color: var(--white-color);
      //height: 86px;
    }
  }

  &--yes {
    background-image: url('../img/btn--yes.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 326px;
    height: 86.3px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &--geography {
    background-image: url('../img/btn-geography.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 327px;
    height: 88px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: translateX(-5px);
    }
  }
}
