.directory {
  padding: 73px 0 81px 0;
  background-image: url('../img/bg-directory.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 130px;

  &__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: var(--white-color);
    margin-bottom: 70px;
  }
}
