.social {
  display: flex;
  align-items: center;
  gap: 12px;

  &__item {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    &--whatsapp {
      background: linear-gradient(166.15deg, #1CE218 9.89%, #039D00 94.68%);
      box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25),
        inset 0px -3px 12px rgba(255, 255, 255, 0.35);
    }

    &--tg {
      background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
      box-shadow: inset 0px 3px 4px rgba(255, 255, 255, 0.25),
        inset 0px -3px 12px rgba(255, 255, 255, 0.35);
    }
  }

  &__icon {

    &--whatsapp {
      width: 20px;
      height: 20px;
      margin-top: 4px;
      fill: var(--white-color);
    }

    &--tg {
      margin-top: 5.5px;
      margin-right: 3px;
      width: 20px;
      height: 20px;
      fill: var(--white-color);
    }
  }
}
