html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

body {
  &::-webkit-scrollbar {
    width: 6px;
    background: #bdbdbd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #5AA54F;
    transition: .7s background;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #437a3b;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

.section {
  text-align: center;
  margin-bottom: 60px;

  &__title {
    max-width: 1150px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    background: linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 51.35%, rgba(255, 255, 255, 0) 83.81%),
      #4A4A4A;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-emphasis-color: transparent;

    span {
      font-weight: 600;
      background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%),
        linear-gradient(0deg, #4A4A4A, #4A4A4A),
        linear-gradient(107.71deg, rgba(255, 255, 255, 0) 9.14%, rgba(255, 255, 255, 0.35) 51.35%, rgba(255, 255, 255, 0) 83.81%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-emphasis-color: transparent;
    }
  }
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.burger {
  @include burger;

  width: 23px;
  height: 14px;
  z-index: 9999;

  &--active {
    color: #fff;
    position: fixed;
    right: 20px;
  }
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
