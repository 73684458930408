.location {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 58px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  max-width: 290px;
  gap: 11px;
  margin-bottom: 20px;

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: var(--black-color);
  }

  &__block {
    background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
    border-radius: 13px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}
