.join {
  padding-bottom: 130px;

  &__content {
    display: flex;
    gap: 109px;
  }

  &__left {
    display: flex;
    gap: 57px;
  }

  &__img {
    display: block;
    border-radius: 28px;
    transition: .3s transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__block {
    border-radius: 28px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
