.phone {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px dashed #000000;
  border-radius: 41px;
  padding: 14px 16px;
  position: relative;
  transition: .3s top ease-in-out;

  &:hover {
    &::after {
      top: 36%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: linear-gradient(71.7deg, rgba(255, 255, 255, 0) 19.71%, rgba(255, 255, 255, 0.28) 89.97%, rgba(255, 255, 255, 0) 166.6%),
      #5AA54F;
    transition: .3s top ease-in-out;
  }

  &__icon {
    width: 12px;
    height: 12px;
    fill: #4A4A4A;
  }

  &__link {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    background: linear-gradient(93.35deg, rgba(255, 255, 255, 0) 19.5%, rgba(255, 255, 255, 0.47) 60%, rgba(255, 255, 255, 0) 98.11%),
      #4A4A4A;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-emphasis-color: transparent;
  }
}
