.reasons {
  padding-bottom: 130px;

  &__cards {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 37px;
    column-gap: 37px;
    max-width: 1030px;
    margin: 0 auto;
  }

  &__card {
    background: linear-gradient(180deg, #5296E7 0%, #204B9B 100%);
    border-radius: 12px;
    max-width: 316px;

    &--full {
      min-width: 672px;
      position: relative;
      overflow: hidden;
    }
  }

  &__block {
    position: absolute;
    right: -70px;
    top: -40px;
  }

  &__content {
    padding: 45px 20px 20px 45px;
    height: 100%;
  }

  &__name {
    font-weight: 700;
    font-size: 29px;
    line-height: 168.6%;
    color: var(--white-color);
    margin-bottom: 25px;
  }

  &__descr {
    font-weight: 400;
    font-size: 18px;
    line-height: 125.1%;
    color: var(--white-color);
    max-width: 380px;
  }
}
